import React from "react"
import Font24 from "../../components/Fonts/Font24"
import Font22 from "../../components/Fonts/Font22"
import Font16 from "../../components/Fonts/Font16"

const Manual = () => {
  return (
    <main>
      <br />
      <br />
      <Font24>OGÓLNE ZASADY WYNAGRADZANIA ZLECENIOBIORCÓW</Font24>
      <Font16>
        (wprowadzone Uchwałą zarządu Spółki z dnia 01.07.2020r. w Katowicach)
      </Font16>
      <br />
      <br />
      <Font22>
        § 1. Możliwość przyznania zaliczki na poczet wynagrodzenia za
        świadczenie usług w Niemczech
      </Font22>
      <br />
      <Font16>
        1. Spółka na złożony przez Zleceniobiorcę wniosek może mu wypłacić
        zaliczkę na poczet wynagrodzenia za świadczenie usług w Niemczech.
      </Font16>
      <br />
      <Font16>
        2. Wniosek o wypłatę zaliczki na poczet wynagrodzenia może zostać
        złożony w dowolnej formie, a decyzja o wypłacie zaliczki na poczet
        wynagrodzenia należy do Spółki. Zleceniobiorca nie ma roszczenia o
        wypłatę zaliczki na poczet wynagrodzenia.
      </Font16>
      <br />
      <Font16>
        3. Zaliczka na poczet wynagrodzenia wypłacona Zleceniobiorcy przez
        Spółkę stanowi część jego wynagrodzenia.
      </Font16>
      <br />
      <br />
      <Font22>
        § 2. Możliwość przyznania zaliczki zwrotnej na poczet zwiększonych
        kosztów pobytu za granicą (w Niemczech).
      </Font22>
      <br />
      <Font16>
        1. Z uwagi na termin wypłaty wynagrodzenia za świadczenie usług opieki
        domowej i pomocy w gospodarstwie domowym na terenie Niemiec Spółka może
        wypłacić zleceniobiorcy Zaliczkę na poczet kosztów utrzymania za granicą
        (dalej jako: „Zaliczka Kosztowa (zwrotna)”).
      </Font16>
      <br />
      <Font16>
        2. Celem Zaliczki Kosztowej jest umożliwienie Zleceniobiorcy pokrycia
        wydatków na środki higieniczne, leki, dodatkowe wyżywienie, czy inne
        niezbędne rzeczy związane z jego codziennym funkcjonowaniem.
      </Font16>
      <br />
      <Font16>
        3. Zaliczka Kosztowa podlega zwrotowi nie później niż do upływu Okresu
        Rozliczeniowego, a w przypadku jej niezwrócenia przez Zleceniobiorcę w
        tym terminie Spółka potrąci ją w kwocie w jakiej została wypłacona z
        należnego Zleceniobiorcy wynagrodzenia.
      </Font16>
      <br />
      <Font16>
        4. Zaliczka Kosztowa w miesiącu kalendarzowym nie może przekroczyć 30%
        wynagrodzenia przysługującego Zleceniobiorcy za trzy pełne miesiące
        świadczenia usług opieki domowej i pomocy w gospodarstwie domowym albo
        45% wynagrodzenia przysługującego Zleceniobiorcy za dwa pełne miesiące
        świadczenia usług opieki i pomocy w gospodarstwie domowym.
      </Font16>
      <br />
      <Font16>
        5. Zaliczka Kosztowa (zwrotna) nie stanowi wynagrodzenia Zleceniobiorcy
        i nie powoduje po jego stronie zmniejszenia pasywów, ani zwiększenia
        aktywów.
      </Font16>
      <br />
      <Font16>
        6. Wniosek o wypłatę Zaliczki kosztowej może zostać złożony przez
        zleceniobiorcę w dowolnej formie.
      </Font16>
      <br />
      <Font16>
        7. Zleceniobiorca nie ma roszczenia o wypłatę zaliczki kosztowej na
        poczet zwiększonych kosztów utrzymania.
      </Font16>
      <br />
      <Font16>
        8. Zaliczka Kosztowa może zostać wypłacona po warunkiem, że świadczenie
        usług opieki domowej i pomocy w gospodarstwie domowym na terenie Niemiec
        zostało rozpoczęte i usługi były świadczone przez okres co najmniej
        trzech pełnych dni. Zaliczka Zwrotna zostanie wypłacona nie wcześniej
        aniżeli w miesiącu następującym po miesiącu, w którym Zleceniobiorca
        rozpoczął świadczenie usług opieki.
      </Font16>
      <br />
      <Font16>
        9. Od powyższych zapisów regulaminu dotyczących zasad wypłacania
        Zaliczek Kosztowych, dopuszcza się wyjątki, o których decyduje wyłącznie
        Spółka.
      </Font16>
      <br />
      <br />
      <Font22>§ 3. Bonus</Font22>
      <br />
      <Font16>
        1. Spółka ustala wypłatę bonusu dodatkowego, o którym mowa w
        oświadczeniu Zleceniobiorcy dotyczącym terminu wypłaty wynagrodzenia,
        według poniższych zasad:
      </Font16>
      <Font16 sx={{ marginLeft: "20px" }}>
        a) za świadczenie usług opieki domowej i pomocy w gospodarstwie domowym
        na terenie Niemiec, nieprzerwanie przez okres co najmniej 61 dni
        Zleceniobiorcy przysługuje bonus w wysokości 20,00 netto EUR, albo
      </Font16>
      <Font16 sx={{ marginLeft: "20px" }}>
        b) za świadczenie usług opieki domowej i pomocy w gospodarstwie domowym
        na terenie Niemiec, nieprzerwanie przez okres co najmniej 91 dni
        Zleceniobiorcy przysługuje bonus w wysokości 50,00 netto EUR.
      </Font16>
      <br />
      <Font16>
        2. Spółka zastrzega sobie prawo do odmowy wypłaty bonusu, w przypadku
        naruszenia przepisów umowy, ogólnych przepisów prawa lub zasad
        współżycia społecznego.
      </Font16>
      <br />
      <br />
      <Font22>§ 4. Premie dodatkowe</Font22>
      <br />
      <Font16>
        1. Spółka może ustalić zasady wypłacania premii dodatkowych po
        spełnieniu określonych warunków, które opisane będą w osobnych
        regulaminach (dalej jako: „Regulamin premii”).
      </Font16>
      <br />
      <Font16>
        2. Spółka zastrzega sobie prawo do odmowy wypłaty premii dodatkowej, w
        przypadku nienależytego wykonania umowy, świadczenia usług przez okres
        krótszy niż okres wskazany w Zleceniu Podróży, naruszenia zapisów umowy,
        ogólnych przepisów prawa lub zasad współżycia społecznego.
      </Font16>
    </main>
  )
}

export default Manual
