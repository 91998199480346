import React from "react"
import Layout from "hoc/Layout"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import Manual from "../containers/Manual/Manual"

const ManualPage = () => {
  const SITE_NAME = "Instrukcja wynagradzania"
  const breadcrumbsData = [
    {
      title: "Strona Główna",
      link: "/",
    },
    SITE_NAME,
  ]

  return (
    <Layout breadcrumbsData={breadcrumbsData} seoTitle={SITE_NAME}>
      <Hr mb={4} mt={6} />
      <Font42 mb={4}>{SITE_NAME}</Font42>
      <Manual />
    </Layout>
  )
}

export default ManualPage
